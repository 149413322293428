* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: 'Lato', sans-serif; */
  font-family: "Poppins", sans-serif;
  height: auto;
  overflow-x: hidden;
}

/*font styling*/
.fat-face {
  font-family: "Archivo Black", sans-serif;
}
.baskerville {
  font-family: "Libre Baskerville", serif;
}

/*NavBar.jsx CSS*/
.menu-text {
  font-family: "Source Code Pro", monospace;
}
.rotateC {
  transform: rotate3d(0, 1, 0, 360deg);
  transition: transform 1s ease-in-out;
}

/*Home.jsx CSS*/
.cursor-animation {
  animation: blink 0.75s step-end infinite;
}


@keyframes blink {
  from,
  to {
    background-color: transparent;
  }
  50% {
    background-color: #fae72a;
  }
}

.hello-text {
  font-size: clamp(2rem, 8vw, 5rem);
}
.mouse-scroll {
  background: linear-gradient(
    170deg,
    rgba(122, 122, 124, 0.918),
    rgb(123, 124, 124)
  );
  border-radius: 50%;
  animation: scrollingDown 1.5s linear infinite;
}
@keyframes scrollingDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}

@keyframes scrollingUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(-10px);
    
  }
}

@media (max-width: 768px) {
  .mouse-scroll {
    animation: scrollingUp 1.5s linear infinite;
   
  }
}

/*About.jsx CSS*/
.about-text {
  font-family: "Poppins", sans-serif;
  /* font-family: 'Lora', serif; */
  font-size: clamp(1rem, 3.7vw, 2.2rem);
}
.about-text-slanted {
  animation: slanted 500ms ease-out;
}
.about-emphasis {
  /*font-family: 'Libre Baskerville', serif;*/
  font-family: "Lora", serif;
}

@keyframes slanted {
  0% {
    transform: skewX(40deg) translate(0, 100%);
  }
  20% {
    transform: skewX(35deg) translate(0, 90%);
  }
  40% {
    transform: skewX(30deg) translate(0, 70%);
  }
  60% {
    transform: skewX(20deg) translate(0, 40%);
  }
  80% {
    transform: skewX(10deg) translate(0, 20%);
  }
  100% {
    transform: skewX(0deg) translate(0, 0);
  }
}

/*Projects.jsx CSS*/

/*Contacts.jsx CSS*/
.contact-text {
  font-family: "Lora", serif;
}

body::-webkit-scrollbar {
  width: 0.6em;
}

body::-webkit-scrollbar-track {
  background: #fcfcfe;
}

body::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 2rem;
}

.myCursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 20px;
  height: 40px;
  border: 1px solid #a9a9a9;
  border-radius: 20px;
  z-index: 1;
  padding-top: 4px;
}

.mouseBall {
  display: block;
  width: 8px;
  height: 8px;
  background: linear-gradient(
    170deg,
    rgba(122, 122, 124, 0.918),
    rgb(123, 124, 124)
  );
  border-radius: 50%;
  margin: auto;
}
